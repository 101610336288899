<template>
  <b-container fluid>
    <HXContentCard title="COLILLA DE PAGO" defaultToBack>
      <!-- <p>{{ itemNominaLiquidacion }}</p> -->
      <p class="text-center mt-3 text-black">Conoce el detalle de los devengados y deducciones incluidos en la
        liquidación de la nómina de este período</p>
      <button class="ml-3 btn px-4 py-2 btn-success my-3" style='width: fit-content' @click="toPdf()">Generar
        Desprendible</button>
        <!-- {{persona}} -->
        <form>
          <b-row>
            <b-col sm="12">
              <card>
                <template v-slot:headerTitle> </template>
                <div class="row d-flex justify-content-center pt-4 px-5 pb-3">
                  <div class="card col-md-3 bg-dark p-3 mx-1 mb-2 ">
                    <!-- <p>4142452545</p> -->
                    <p>Dirección: {{ item.persona.direccion }}</p>
                    <p>{{ item.persona ? item.persona.correo : "" }}</p>
                    <p>Identificación: {{ item.persona ? item.persona.numeroDocumento : "" }}</p>
    
                  </div>
                  <div class="card col-md-3 bg-dark p-3 mx-1 mb-2">
                    <p>Empleado: {{ (item.persona ? item.persona.nombres : "") + " " + (item.persona ?
                      item.persona.apellidos : "") }}
                    </p>
                    <p>Cargo: {{ persona.cargo }}</p>
                    <p>Método de pago: {{ metodoDePago }}</p>
    
                  </div>
                  <div class="card col-md-3 bg-dark p-3 mx-1  mb-2">
                    <p>Fecha de inicio de liq.: {{ item.fechaInicio }}</p>
                    <p>Fecha de fin de liq.: {{ item.fechaFin }}</p>
                    <p>Días liquidados: {{ diasLiquidados }}</p>
                  </div>
                </div>
    
                <!-- <div class="col-md-4">
                    <div class="mt-4 text-left px-3 py-4 bg-dark rounded">
                      <label class="form-label mt-2">{{ item.persona ? item.persona.numeroDocumento : "" }}</label
                      ><br />
                      <hr class="p-0 pb-1 m-0" />
                      <label class="form-label">Dirección:  {{ item.persona.direccion }}</label><br />
                      <hr class="p-0 pb-1 m-0" />
                      <label class="form-label pb-0 mb-1">{{ item.persona ? item.persona.correo : "" }}</label
                      ><br />
                      <hr class="p-0 pb-1 m-0" />
                    </div>
                  </div>
                  <div class="col-md-4 text-left pr-4">
                    <div class="mt-4 row text-left bg-dark py-4 pl-0 rounded">
                      <div class="col-6 mt-2 pr-0 mr-0">
                        <label class="form-label">Empleado</label><br />
                        <hr class="p-0 pb-1 m-0" />
                        <label class="form-label">Identificación</label><br />
                        <hr class="p-0 pb-1 m-0" />
                        <label class="form-label">Cargo</label><br />
                        <hr class="p-0 pb-1 m-0" />
                      </div>
                      <div class="col-6 mt-2 pl-0 ml-0">
                        <label class="form-label">{{ (item.persona ? item.persona.nombres : "") + " " + (item.persona ? item.persona.apellidos : "") }}</label
                        ><br />
                        <hr class="p-0 pb-1 m-0" />
                        <label class="form-label">{{  item.persona ? item.persona.numeroDocumento : ""  }}</label
                        ><br />
                        <hr class="p-0 pb-1 m-0" />
                        <label class="form-label">{{ persona.cargo }}</label><br />
                        <hr class="p-0 pb-1 m-0" />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 pt-4 pl-4">
                    <div class="row text-left bg-dark pt-3 pb-3 pl-0 rounded">
                      <div class="col-6 pr-0 mr-0">
                        <div class="text-left">
                          <label class="form-label">Fecha de inicio de liq.</label><br />
                          <hr class="p-0 pb-1 m-0" />
                          <label class="form-label">Fecha de fin de liq.</label><br />
                          <hr class="p-0 pb-1 m-0" />
                          <label class="form-label">Método de pago</label><br />
                          <hr class="p-0 pb-1 m-0" />
                          <label class="form-label pb-0 mb-1">Días liquidados</label><br />
                        </div>
                      </div>
                      <div class="col-6 pl-0 ml-0">
                        <div class="text-left">
                          <label class="form-label">{{item.fechaInicio}}</label><br />
                          <hr class="p-0 pb-1 m-0" />
                          <label class="form-label">{{item.fechaFin}}</label><br />
                          <hr class="p-0 pb-1 m-0" />
                          <label class="form-label">{{item.persona.metodoPago}}</label><br />
                          <hr class="p-0 pb-1 m-0" />
                          <label class="form-label pb-0 mb-1">30</label><br />
                        </div>
                      </div>
                    </div>
                  </div> -->
    
              </card>
    
              <!-- <template v-slot:headerTitle> </template> -->
              <div class="px-5 pb-3">
                <div class="row">
                  <div class="col-12">
                    <CardTable :data="dataCards" />
                  </div>
                  <!-- <div class="col-12 col-md">
                      <BtnAddCardTable text="Abrir Ticket" url="AgregarTickets" />
                    </div> -->
                </div>
              </div>
    
              <!-- empleados -->
              <!-- <card>
                <div class="container px-5 pt-3">
                  <div class="row">
                    <div class="col-12">
                      <p><b>INGRESOS</b></p>
                      <table class="table table-responsive">
                        <thead>
                          <tr>
                            <th scope="col">Concepto</th>
                            <th scope="col">valor</th>
                          </tr>
                        </thead>
                        <tbody v-for="itemTable of nominaLiquidacionPersonaDetalle" :key="itemTable.id">
                          <template v-if="itemTable.tDev > 0">
                            <tr>
                              <td>{{ itemTable.concepto }}</td>
                              <td>{{ Number(itemTable.tDev).toLocaleString("es-CO", { style: "currency", currency: "COP" }) }}</td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-12">
                      <p><b>DEDUCCIONES</b></p>
                      <table class="table table-responsive">
                        <thead>
                          <tr>
                            <th scope="col">Concepto</th>
                            <th scope="col">valor</th>
                          </tr>
                        </thead>
                        <tbody v-for="itemTable2 of nominaLiquidacionPersonaDetalle" :key="itemTable2.id">
                          <tr>
                            <template v-if="itemTable2.tDed > 0">
                              <td>{{ itemTable2.concepto }}</td>
                              <td>{{ Number(itemTable2.tDed).toLocaleString("es-CO", { style: "currency", currency: "COP" }) }}</td>
                            </template>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </card> -->
              <card>
                <div class="row px-5 py-4">
                  <div class="col-md-6 py-2">
                    <div class="pb-3">
                      <b class="pb-3">INGRESOS</b>
                    </div>
                    <div class="row px-1">
                      <div class="col-12 ">
                        <div class="row bg-dark p-2"
                          style="border-bottom: 2px solid #6f8ac4; border-top: 2px solid #6f8ac4;">
                          <div class="col-6">
                            <span>Concepto</span>
                          </div>
                          <div class="col-6">
                            <span>valor</span>
                          </div>
                        </div>
                        <div class="row" v-for="itemTable of nominaLiquidacionPersonaDetalle" :key="itemTable.id">
                          <div v-if="itemTable.tDev > 0" class="col-6 pt-3">
                            <span>{{ itemTable.concepto }}</span>
                          </div>
                          <div v-if="itemTable.tDev > 0" class="col-6 pt-3">
                            <span>{{ Number(itemTable.tDev).toLocaleString("es-CO", { style: "currency", currency: "COP" })
                              }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <table class="table " >
                      <thead>
                        <tr>
                          <th >Concepto</th>
                          <th >valor</th>
                        </tr>
                      </thead>
                      <tbody v-for="itemTable of nominaLiquidacionPersonaDetalle" :key="itemTable.id">
                        <template v-if="itemTable.tDev > 0">
                          <tr>
                            <td>{{ itemTable.concepto }}</td>
                            <td>{{ Number(itemTable.tDev).toLocaleString("es-CO", { style: "currency", currency: "COP" }) }}</td>
                          </tr>
                        </template>
                      </tbody>
                    </table> -->
                  </div>
                  <div class="col-md-6 py-2">
                    <div class="pb-3">
                      <b class="">DEDUCCIONES</b>
                    </div>
                    <div class="row px-1 ">
                      <div class="col-12">
                        <div class="row bg-dark p-2"
                          style="border-bottom: 2px solid #6f8ac4; border-top: 2px solid #6f8ac4;">
                          <div class="col-6">
                            <span>Concepto</span>
                          </div>
                          <div class="col-6">
                            <span>valor</span>
                          </div>
                        </div>
                        <div class="row" v-for="itemTable2 of nominaLiquidacionPersonaDetalle" :key="itemTable2.id">
                          <div v-if="itemTable2.tDed > 0" class="col-6 pt-3">
                            <span>{{ itemTable2.concepto }}</span>
                          </div>
                          <div v-if="itemTable2.tDed > 0" class="col-6 pt-3">
                            <span>{{ Number(itemTable2.tDed).toLocaleString("es-CO", { style: "currency", currency: "COP" })
                              }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <table class="table ">
                      <thead>
                        <tr>
                          <th scope="col">Concepto</th>
                          <th scope="col">valor</th>
                        </tr>
                      </thead>
                      <tbody v-for="itemTable2 of nominaLiquidacionPersonaDetalle" :key="itemTable2.id">
                        <tr>
                          <template v-if="itemTable2.tDed > 0">
                            <td>{{ itemTable2.concepto }}</td>
                            <td>{{ Number(itemTable2.tDed).toLocaleString("es-CO", { style: "currency", currency: "COP" }) }}</td>
                          </template>
                        </tr>
                      </tbody>
                    </table> -->
                  </div>
                  <!-- <div class="col-12 ">
                    <p><b>INGRESOS</b></p>
                    <table class="table " >
                      <thead>
                        <tr>
                          <th >Concepto</th>
                          <th >valor</th>
                        </tr>
                      </thead>
                      <tbody v-for="itemTable of nominaLiquidacionPersonaDetalle" :key="itemTable.id">
                        <template v-if="itemTable.tDev > 0">
                          <tr>
                            <td>{{ itemTable.concepto }}</td>
                            <td>{{ Number(itemTable.tDev).toLocaleString("es-CO", { style: "currency", currency: "COP" }) }}</td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-12">
                    <p><b>DEDUCCIONES</b></p>
                    <table class="table ">
                      <thead>
                        <tr>
                          <th scope="col">Concepto</th>
                          <th scope="col">valor</th>
                        </tr>
                      </thead>
                      <tbody v-for="itemTable2 of nominaLiquidacionPersonaDetalle" :key="itemTable2.id">
                        <tr>
                          <template v-if="itemTable2.tDed > 0">
                            <td>{{ itemTable2.concepto }}</td>
                            <td>{{ Number(itemTable2.tDed).toLocaleString("es-CO", { style: "currency", currency: "COP" }) }}</td>
                          </template>
                        </tr>
                      </tbody>
                    </table>
                  </div> -->
                </div>
              </card>
            </b-col>
          </b-row>
        </form>
        <form>
          <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="true"
            :paginate-elements-by-height="2400" filename="desprendible" :pdf-quality="2" :manual-pagination="false"
            pdf-format="a4" :pdf-margin="10" pdf-orientation="portrait" pdf-content-width="1000px"
            @progress="onProgress($event)" ref="html2Pdf" :html-to-pdf-options="htmltopdfoptions">
            <!-- </vue-html2pdf> -->
    
            <section class="m-1 mt-4 text-center bgwhite " slot="pdf-content">
              <h4 class="text-center text-dark"><b>COLILLA DE PAGO: </b></h4>
              <p class="text-center mt-3 text-dark">Detalle de los devengados y deducciones incluidos en la liquidación de
                la
                nómina de este período</p>
              <b-row class="bgwhite">
                <b-col sm="12">
                  <card class="bgwhite">
                    <template v-slot:headerTitle> </template>
                    <div class="row text-center d-flex justify-content-center pt-4  ">
                      <div class=" bgwhite col-md-3 p-3 mx-1 mb-2 ">
                        <!-- <p>4142452545</p> -->
                        <p class="text-dark">Dirección: {{ item.persona.direccion }}</p>
                        <p class="text-dark">{{ item.persona ? item.persona.correo : "" }}</p>
                        <p class="text-dark">Identificación: {{ item.persona ? item.persona.numeroDocumento : "" }}</p>
    
                      </div>
                      <div class=" bgwhite col-md-3 p-3 mx-1 mb-2">
                        <p class="text-dark">Empleado: {{ (item.persona ? item.persona.nombres : "") + " " + (item.persona ?
                          item.persona.apellidos : "") }}</p>
                        <p class="text-dark">Cargo: {{ persona.cargo }}</p>
                        <p class="text-dark">Método de pago: {{ metodoDePago }}</p>
    
                      </div>
                      <div class=" bgwhite col-md-3 p-3 mx-1  mb-2">
                        <p class="text-dark">Fecha de inicio de liq.: {{ item.fechaInicio }}</p>
                        <p class="text-dark">Fecha de fin de liq.: {{ item.fechaFin }}</p>
                        <p class="text-dark">Días liquidados: {{ diasLiquidados }}</p>
                      </div>
                    </div>
                    <!-- <div class="row px-5 mb-0 text-dark bgwhite">
                    <div class="col-md-4 text-left pr-4">
                      <div class="mt-4 row text-left py-4 pl-0 rounded bg-none">
                        <div class="col-4 mt-0 pr-0 mr-0">
                          <label class="form-label bold">Nombres:</label><br />
                          <label class="form-label bold">Apellidos:</label><br />
                          <label class="form-label bold">Identificación:</label><br />
                          <label class="form-label bold">Cargo:</label><br />
                        </div>
                        <div class="col-6 mt-0 pl-0 ml-0">
                          <label class="form-label">{{ item.persona ? item.persona.nombres : "" }}</label
                          ><br />
                          <label class="form-label">{{ item.persona ? item.persona.apellidos : "" }}</label
                          ><br />
                          <label class="form-label">{{  item.persona ? item.persona.numeroDocumento : ""  }}</label
                          ><br />
                          <label class="form-label">--</label><br />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 text-left pr-4">
                      <div class="mt-4 row text-left py-4 pl-0 rounded bg-none">
                        <div class="col-4 mt-0 pr-0 mr-0">
                          <label class="form-label bold">Ciudad:</label><br />
                          <label class="form-label bold">Dirección:</label><br />
                          <label class="form-label bold">Correo:</label><br />
                        </div>
                        <div class="col-6 mt-0 pl-0 ml-0">
                          <label class="form-label">--</label><br />
                          <label class="form-label">{{persona.Direccion}}</label><br />
                          <label class="form-label">{{ item.persona ? item.persona.correo : "" }}</label
                          ><br />
                        </div>
                      </div>
                    </div>
                  </card>
    
                    <div class="col-md-4 text-left pr-4">
                      <div class="mt-4 row text-left py-4 pl-0 rounded bg-none">
                        <div class="col-4 mt-0 pr-0 mr-0">
                          <label class="form-label bold">Inicio liq.:</label><br />
                          <label class="form-label bold">Fin liq.:</label><br />
                          <label class="form-label bold">Mét. de pago:</label><br />
                          <label class="form-label bold">Días liquidados:</label><br />
                        </div>
                        <div class="col-6 mt-0 pl-0 ml-0">
                          <label class="form-label">--</label><br />
                          <label class="form-label">--</label><br />
                          <label class="form-label">0</label><br />
                          <label class="form-label">0</label><br />
                        </div>
                      </div>
                    </div>
                  </div> -->
                  </card>
    
                  <!-- <div class="px-5 pb-3">
                  <div class="row">
                    <div class="col-12">
                      <CardTable :data="dataCards" />
                    </div>
                  </div>
                </div> -->
    
                  <!-- empleados -->
                  <card class="bg-none mt-0 bgwhite">
                    <div class="row px-5 pb-4">
                      <div class="col-md-6 py-2">
                        <div class="pb-3">
                          <b class="pb-3">INGRESOS</b>
                        </div>
                        <div class="row px-1">
                          <div class="col-12 ">
                            <div class="row text-left text-dark p-2"
                              style="border-bottom: 2px solid black; border-top: 2px solid black;">
                              <div class="col-6">
                                <span class="text-dark">Concepto</span>
                              </div>
                              <div class="col-6">
                                <span class="text-dark">valor</span>
                              </div>
                            </div>
                            <div class="row text-left" v-for="itemTable of nominaLiquidacionPersonaDetalle"
                              :key="itemTable.id">
                              <div v-if="itemTable.tDev > 0" class="col-6 pt-3 text-dark">
                                <span class="text-dark">{{ itemTable.concepto }}</span>
                              </div>
                              <div v-if="itemTable.tDev > 0" class="col-6 pt-3">
                                <span class="text-dark ">{{ Number(itemTable.tDev).toLocaleString("es-CO", {
                                  style:
                                    "currency",
                                  currency: "COP"
                                }) }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 py-2">
                        <div class="pb-3">
                          <b class="">DEDUCCIONES</b>
                        </div>
                        <div class="row px-1 ">
                          <div class="col-12">
                            <div class="row text-left text-dark p-2"
                              style="border-bottom: 2px solid black; border-top: 2px solid black;">
                              <div class="col-6">
                                <span class="text-dark">Concepto</span>
                              </div>
                              <div class="col-6 text-left">
                                <span class="text-dark">valor</span>
                              </div>
                            </div>
                            <div class="row text-left" v-for="itemTable2 of nominaLiquidacionPersonaDetalle"
                              :key="itemTable2.id">
                              <div v-if="itemTable2.tDed > 0" class="col-6 pt-3 text-dark">
                                <span class="text-dark">{{ itemTable2.concepto }}</span>
                              </div>
                              <div v-if="itemTable2.tDed > 0" class="col-6 pt-3 text-dark">
                                <span class="text-dark">{{ Number(itemTable2.tDed).toLocaleString("es-CO", {
                                  style:
                                    "currency",
                                  currency: "COP"
                                }) }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
    
                      </div>
                    </div>
                    <!-- <div class="row px-5 pt-3 bgwhite">
                    <div class="col-md-6 bgwhite">
                      <p class="text-dark"><b>INGRESOS</b></p>
                      <table class="table bg-white">
                        <thead class="bg-white">
                          <tr class="bg-white">
                            <th class="bg-white" scope="col">Concepto</th>
                            <th class="bg-white" scope="col">valor</th>
                          </tr>
                        </thead>
                        <tbody class="border" v-for="itemTable of nominaLiquidacionPersonaDetalle" :key="itemTable.id">
                          <template v-if="itemTable.tDev > 0">
                            <tr>
                              <td class="border">{{ itemTable.concepto }}</td>
                              <td class="border">{{ Number(itemTable.tDev).toLocaleString("es-CO", { style: "currency", currency: "COP" }) }}</td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-md-6 ">
                      <p class="text-dark"><b>DEDUCCIONES</b></p>
                      <table class="table  bg-white">
                        <thead class="bg-white">
                          <tr class="bg-white">
                            <th scope="col" class="bg-white">Concepto</th>
                            <th scope="col" class="bg-white">valor</th>
                          </tr>
                        </thead>
                        <tbody class="border" v-for="itemTable2 of nominaLiquidacionPersonaDetalle" :key="itemTable2.id">
                          <tr >
                            <template v-if="itemTable2.tDed > 0">
                              <td class="border">{{ itemTable2.concepto }}</td>
                              <td class="border">{{ Number(itemTable2.tDed).toLocaleString("es-CO", { style: "currency", currency: "COP" }) }}</td>
                            </template>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div> -->
    
    
                    <!-- <div class="row">
                        <div class="col-3">
                        <span class="text-dark bold m-3">TOTALES</span>
    
                        </div>
                        <div class="col-6">
                          <p class="text-dark"><b>DEDUCCIONES</b></p>
                          <table class="table bg-white">
                            <thead class="bg-white">
                              <tr class="bg-white">
                                <th scope="col" class="bg-white">Concepto</th>
                                <th scope="col" class="bg-white">valor</th>
                              </tr>
                            </thead>
                            <tbody class="border" v-for="itemTable2 of nominaLiquidacionPersonaDetalle" :key="itemTable2.id">
                              <tr class="border">
                                <template v-if="itemTable2.tDed > 0">
                                  <td class="border">{{ itemTable2.concepto }}</td>
                                  <td class="border">{{ Number(itemTable2.tDed).toLocaleString("es-CO", { style: "currency", currency: "COP" }) }}</td>
                                </template>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div> -->
    
                    <div class="text-dark text-left ml-5 mr-5 p-1 border">
                      <div class="row">
                        <div class="col-3">
                          <span class="text-dark bold m-3">TOTALES</span>
                        </div>
                        <div class="col-3">
                          <span class="text-dark bold">Devengos:{{
                            nominaLiquidacionPersonaDetalle.reduce((prev, curr) => prev + Number(curr.tDev),
                              0).toLocaleString("es-CO", { style: "currency", currency: "COP" })
                          }}</span>
                        </div>
                        <div class="col-3">
                          <span class="text-dark bold">Deducciones:{{
                            listaLiquidacionPersona.reduce((prev, curr) => prev + Number(curr.totalDeducciones),
                              0).toLocaleString("es-CO", { style: "currency", currency: "COP" })
                          }}</span>
                        </div>
                        <div class="col-3">
                          <span class="text-dark bold">Neto a pagar:{{
                            listaLiquidacionPersona.reduce((prev, curr) => prev + Number(curr.totalPago),
                              0).toLocaleString("es-CO", { style: "currency", currency: "COP" })
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-5">
                      <div class="col-6">
                        _______________________________________<br />
                        &nbsp; Recibido trabajador
                      </div>
                    </div>
    
                  </card>
                </b-col>
              </b-row>
            </section>
          </vue-html2pdf>
        </form>
    </HXContentCard>
  </b-container>
</template>
<script>
  import { core } from "../../../../config/pluginInit";
  import CardTable from "../../../../components/cardTable/CardTableV2.vue";
  // const Swal = require("sweetalert2");
  //import Vue from 'vue';
  import VueHtml2pdf from "vue-html2pdf";

  //Vue.use(VueHtml2pdf);

  export default {
    components: {
      CardTable,
      VueHtml2pdf,
    },
    name: "DataTable",
    data() {
      return {
        metodoDePago: null,
        diasLiquidados: '',
        usuario: { rol: { nivel: 0 } },
        id: "",
        selected: null,
        item: {
          fechaInicio: null, //this.$moment.tz(new Date(), "America/Costa_Rica").format("YYYY-MM-DD"),
          fechaFin: null, //this.$moment.tz(new Date(), "America/Costa_Rica").format("YYYY-MM-DD"),
          CantidadEmpleados: 0.0,
          TotalSalarios: 0,
          TotalPagosAdicionales: 0,
          TotalDeducciones: 0,
          TotalCostos: 0,
          TotalAPagar: 0,
          EmpresaId: "",
          SucursalId: "",
          Descripcion: "",
          Estado: 1,
          persona: { nombres: "", apellidos: "", numeroDocumento: "", correo: "" },
        },
        ListEmpresas: [],
        ListSucursales: [],
        listaItems: [],
        // listaEmpleados: [],
        nominaConfiguracion: {},
        listaDeducciones: [],
        listaDevengados: [],
        listaLiquidacionPersona: [],
        listaLiquidacionPersonaDetalle: [],
        nominaLiquidacionPersonaDetalle: [],
        nuevoItem: {},
        usuarioSeleccionado: null,
        porcenta: 0.75,
        porcenta2: 1.1,
        porcenta3: 1,
        valporcenta: 1,
        valporcenta2: 1,
        valporcenta3: 1,
        dataCards: [
          {
            title: "Salario",
            value: () => this.listaLiquidacionPersona.reduce((prev, curr) => prev + Number(curr.salario), 0).toLocaleString("es-CO", { style: "currency", currency: "COP" }),
            iconName: "money-bill-1",
            color: "rgba(71, 136, 255, 1)",
            tipo: "shield",
            animated: "bounce",
          },
          {
            title: "Ingresos adicionales",
            value: () =>
              this.listaLiquidacionPersona.reduce((prev, curr) => prev + Number(curr.totalIngresosAdicionales), 0).toLocaleString("es-CO", { style: "currency", currency: "COP" }),
            iconName: "hand-point-up",
            color: "rgba(71,136,255,1)",
            tipo: "shield",
            animated: "bounce",
          },
          {
            title: "Deducciones",
            value: () => this.listaLiquidacionPersona.reduce((prev, curr) => prev + Number(curr.totalDeducciones), 0).toLocaleString("es-CO", { style: "currency", currency: "COP" }),
            iconName: "hand-point-down",
            color: "rgba(223, 18, 46, 1)",
            tipo: "shield",
            animated: "bounce",
          },
          {
            title: "Total a pagar",
            value: () => this.listaLiquidacionPersona.reduce((prev, curr) => prev + Number(curr.totalPago), 0).toLocaleString("es-CO", { style: "currency", currency: "COP" }),
            iconName: "hand-holding-dollar",
            color: "rgba(71, 136, 255, 1)",
            tipo: "shield",
            animated: "bounce",
          },
          // {
          //   title: "Total Costo",
          //   value: () =>
          //   this.listaItems.filter(
          //       (tick) => tick.prioridad == 1
          //     ).length,
          //   iconName: "hand-point-up",
          //   color: "rgba(71, 136, 255, 1)",
          //   tipo: "shield",
          //   animated: "bounce",
          //   onActivate: () => {
          //     this.dataTableInstance.search("Baja").draw();
          //     console.log("Activated");
          //   },
          //   onDeactivate: () => {
          //     this.dataTableInstance.search("").draw();
          //     console.log("Deactivate");
          //   },
          // },
        ],
        usuario_id: null,
        htmltopdfoptions: {
          margin: 0.5,
          image: {
            type: "jpeg",
            quality: 0.98,
          },

          enableLinks: false,

          html2canvas: {
            scale: 1,
          },

          jsPDF: {
            unit: "in",
            format: "a4",
            // orientation: "portrait",
            orientation: "landscape",
          },
        },
        persona: {},
        itemNominaLiquidacion: {}
      };
    },

    async mounted() {
      this.$isLoading(true);
      this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
      this.item.EmpresaId = this.usuario.empresa.id;
      this.usuario_id = this.$route.params.user;

      this.id = this.$route.params.id;
      await this.getDeducciones();
      await this.getDevengados();
      // await this.getEmpleados();
      await this.getNominaConfiguracion();
      await this.getData();
      await this.getMetodoDePago();
      // await this.getEmpresas();
      // await this.getSucursalEmpresa();
      core.index();
      window.$("#datatable_empleados").DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
        },
      });

      window.$("#datatable_empleados2").DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
        },
      });
      this.$isLoading(false);
    },

    methods: {
      async getMetodoDePago() {
        let res = await this.$store.dispatch("hl_get", {
          path: "Trabajador/MetodoDePago/"
        });
        res = res.filter(x => x.id == this.item.persona.metodoPago)
        this.metodoDePago = res[0].nombre
        // alert(res[0].nombre)
      },
      onProgress(event) {
        console.log(`Processed: ${event} / 100`);
      },
      toPdf() {
        //html2pdf(document.getElementById('exportpdf'));
        //this.$refs.html2Pdf.generatePdf();
        this.$refs.html2Pdf.generatePdf();
      },

      async getDevengados() {
        try {
          let res = await this.$store.dispatch("hl_get", {
            path: "Devengado/GetDevengados/",
          });
          this.listaDevengados = res;
          console.log("list devengados", this.listaDevengados);
        } catch (error) {
          this.listaDevengados = {};
          console.log("err", error);
        }
      },
      async getDeducciones() {
        try {
          let res = await this.$store.dispatch("hl_get", {
            path: "Deduccion/GetDeducciones",
          });
          this.listaDeducciones = res;
          console.log("list DEDUCCIONES **************", this.listaDeducciones);
        } catch (error) {
          this.listaDeducciones = {};
          console.log("err", error);
        }
      },
      async getNominaConfiguracion() {
        try {
          let res = await this.$store.dispatch("hl_get", {
            path: "NominaConfiguracion/GetNominaConfiguracion/",
          });
          this.nominaConfiguracion = res;

          console.log("list this.nominaConfiguracion ***************************", this.nominaConfiguracion);
        } catch (error) {
          this.nominaConfiguracion = {};
          console.log("err", error);
        }
      },

      async getData() {

        try {
          let res = await this.$store.dispatch("hl_get", {
            path: "NominaLiquidacion/GetNominaLiquidacionId/" + this.id,
          });
          this.itemNominaLiquidacion = res;
          // console.log("AQUI EL USUARIO REAL *", this.item);
          //obtenemos lista de GetNominaLiquidacionPersonas
          //let str =
          let resLiquidaPersona = await this.$store.dispatch("hl_get", {
            path: "NominaLiquidacion/GetNominaLiquidacionPersonas/" + this.id,
          });
          let item = resLiquidaPersona.filter((x) => x.persona.id == this.usuario_id);
          this.item = item[0];
          console.log('itemLiquidaPersona', item)
          //////////////
          let days = (new Date(this.itemNominaLiquidacion.fechaFin).getTime() - new Date(this.itemNominaLiquidacion.fechaInicio).getTime()) / (1000 * 60 * 60 * 24);
          this.diasLiquidados = (days + 1).toFixed(0);
          /////////////////
          this.item.fechaFin = this.$moment.tz(this.itemNominaLiquidacion.fechaFin, "America/Bogota").format("DD-MM-YYYY");
          this.item.fechaInicio = this.$moment.tz(this.itemNominaLiquidacion.fechaInicio, "America/Bogota").format("DD-MM-YYYY");
          this.listaLiquidacionPersona = item; // this.item.nominaLiquidacionPersonaDetalle
          this.nominaLiquidacionPersonaDetalle = this.item.nominaLiquidacionPersonaDetalle;
          console.log("devenga lisra ", this.nominaLiquidacionPersonaDetalle);
          for await (let itemDetalleL of this.nominaLiquidacionPersonaDetalle) {
            console.log("lista itemdetalle", itemDetalleL);
            if (itemDetalleL.tDev > 0) {
              // console.log('lista dev',itemDetalle.conceptoId)
              console.log("lista 2136", this.listaDevengados);
              let filtroDev = this.listaDevengados.filter((x) => x.id == itemDetalleL.conceptoId);
              console.log("filtroDev", filtroDev);
              itemDetalleL.concepto = filtroDev[0].descripcion;
            }
            if (itemDetalleL.tDed > 0) {
              console.log("lista ded", this.listaDeducciones);
              let filtroDed = this.listaDeducciones.filter((y) => y.id == itemDetalleL.conceptoId);
              itemDetalleL.concepto = filtroDed[0].descripcion;
            }
          }

          console.log("listaLiquidacionPersona----******************> ", this.item);
          console.log("listaLiquidacionPersona nominaLiquidacionPersonaDetalle --- ", this.nominaLiquidacionPersonaDetalle);
          await this.getPersona();
          this.$forceUpdate();
        } catch (error) {
          this.item = {};
          console.log("err", error);
        }
      },
      async getPersona() {
        // console.log('---- personaaaaaaaaaaaaaaaaa' , this.item.persona);

        try {
          console.log('aqui se ecneuntra posible metodo')
          let res = await this.$store.dispatch("hl_get", {
            path: "Persona/GetPersona/" + this.usuario_id,
          });
          console.log('DATOSDEPERSONALIQUIDA', res);

          let resCargo = await this.$store.dispatch("hl_get", {
            path: "PersonaUbicacion/GetByPersona/" + this.usuario_id,
          });

          //this.dataPersona = res;
          this.persona.cargo = resCargo.cargo.nombre
          this.persona.TipoDocumentoId = res.tipoDocumento.id;
          this.persona.NumeroDocumento = res.numeroDocumento;
          this.persona.Apellidos = res.apellidos;
          this.persona.Nombres = res.nombres;
          this.persona.FechaNacimiento = res.fechaNacimiento;
          this.persona.FechaContratacion = res.fechaContratacion;
          this.persona.RolId = res.rol.id;
          this.persona.EstadoCivilId = res.estadoCivil.id;
          this.persona.NivelReporte = res.nivelReporte == 0 ? null : res.nivelReporte;
          if (res.nivelReporte != null) {
            // TODO: como actuar en caso de que el usuario recien creado no tenga nivel de reporte?
            if (res.nivelReporte.includes("[")) {
              this.selectedNivelesReporte = JSON.parse(res.nivelReporte);
            } else {
              this.selectedNivelesReporte.push(Number(res.nivelReporte));
            }
          }
          this.persona.PaisId = res.pais.id;
          this.persona.Direccion = res.direccion;
          this.persona.DepartamentoPaisId = res.departamentoPais.id;
          // this.getDepartmentoPaisList(this.persona.PaisId);
          // this.actualizaMunicipios(this.persona.DepartamentoPaisId);
          this.persona.TelefonoFijo = res.telefonoFijo;
          this.persona.MunicipioId = res.municipio.id;
          this.persona.TelefonoMovil = res.telefonoMovil;

          this.persona.Correo = res.correo;
          this.persona.EstadoUsuario = res.estadoUsuario == 0 ? null : res.estadoUsuario;
          this.persona.JefeDirectoId = res.jefeDirectoId;
          this.persona.CorreoNotificaciones = res.correoNotificaciones;
          this.persona.ImagenPerfilUrl = res.imagenPerfilUrl;

          this.persona.TipoContrato = res.tipoContrato;
          this.persona.SalarioIntegral = res.salarioIntegral == true ? "Si" : "No";
          this.persona.Salario = res.salario;
          this.persona.TipoTrabajador = res.tipoTrabajador;
          this.persona.SubtipoTrabajador = res.subtipoTrabajador;
          this.persona.FrecuenciaPago = res.frecuenciaPago;
          this.persona.AltoRiesgo = res.altoRiesgo == true ? 1 : 0;
          this.persona.AuxilioTransporte = res.auxilioTransporte;
          this.persona.SabadoLaboral = res.sabadoLaboral;
          this.persona.NivelRiesgo = res.nivelRiesgo;
          this.persona.DiasVacacionesAcumulados = res.diasVacacionesAcumulados;
          this.persona.MetodoPago = res.metodoPago;
          this.persona.Banco = res.banco;
          this.persona.NumeroCuenta = res.numeroCuenta;
          this.persona.TipoCuenta = res.tipoCuenta;
          this.persona.Eps = res.eps;
          this.persona.CajaCompensacion = res.cajaCompensacion;
          this.persona.FondoPensiones = res.fondoPensiones;
          this.persona.FondoCesantias = res.fondoCesantias;

          //console.log("dataPersona**********************************", res);
        } catch (error) {
          //this.dataPersona = {};
          console.log("errox", error);
        }
      },
    },
  };
</script>
<style scoped>
  div {
    box-shadow: none !important;
  }

  body {
    font-family: Arial, sans-serif;
    background-color: #e0e8f9;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
    margin: 0;
  }


  th {
    position: sticky;
    top: 0px;
    /* 0px if you don't have a navbar, but something is required */
    background: #0c1a30;
  }

  th {
    position: sticky;
    top: 0px;
    /* 0px if you don't have a navbar, but something is required */
    background: #0c1a30;
  }

  .bg-none,
  .bg-none>label,
  .bg-none>div>label {
    background-color: white;
    color: black;
  }

  .border {
    border: 1px solid black;
  }

  .bg-white {
    background-color: white !important;
    color: black;
    border: 1px solid black !important;
  }

  .bgwhite {
    background-color: white !important;
    color: black;
  }

  .bold {
    font-weight: bold;
  }

  .dark .border {
    border-color: black !important;
  }

  .text-dark {
    color: black !important;
  }

  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table {
    width: 900px;

  }

  table {
    width: 900px;

  }

</style>
